import React, {useState, useEffect, useRef} from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link} from 'react-scroll';
import logoBack from '../img/logo.svg';
import logo from '../img/logo2.svg';
import stars from '../img/stars.png';
import './header.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Main from '../Pages/Main';
import WhyIkigai from '../Pages/WhyIkigai';
import LanguageFlags from "./LanguageFlags";
import {useTranslation} from "react-i18next";
import HowWeWork from "../Pages/HowWeWork";
import Testimonials from "../Pages/Testimonials";
import Great from "../Pages/Great";
import ForDevelopers from "../Pages/ForDevelopers";

import Modal from "../Pages/Modal";
import Calendly from "../Pages/calendly";
import OurService from "../Pages/OurService";

function NavMenu() {
    const {t} = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const [showModal, setShowModal] = useState(false);

    const handleIkigaiClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById('navbar').style.top = '0';
        } else {
            document.getElementById('navbar').style.top = '-100px';
        }
        prevScrollpos = currentScrollPos;
    };

    return (
        <>
            <div className="navbar fixed-top navbar-expand-lg navbar-light p-md-3" id="navbar">
                <Container className="container px-4">
                    <Navbar.Brand className="navbar-brand text-my-own-color d-none d-lg-inline-block" href="#page-top">
                        <div>
                            <div>
                                <a href='/'>
                                    <div>
                                        <img src={logo} height="50" width="50" className="d-inline-block align-top"
                                             alt="logo"/>
                                    </div>
                                    <div className="back">
                                        <img src={logoBack} height="50" width="50" className="d-inline-block align-top"
                                             alt="logo"/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Navbar.Brand>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleMenu}
                        aria-controls="navbarNav"
                        aria-expanded={menuOpen ? 'true' : 'false'}
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <Navbar.Collapse className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
                        <Nav className="mr-auto">
                            <Nav.Link className="nav-item client-reviews-container">
                                <Link
                                    className="nav-link text-my-own-color client-reviews-link"
                                    activeClass="active"
                                    to="/testimonials"
                                    top="50"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    {t('client_reviews')}
                                    <img src={stars} height="20" width="100" className="stars-image" alt="stars"/>
                                </Link>

                            </Nav.Link>
                            <Nav.Link className="nav-item">
                                <Link className="nav-link text-my-own-color" activeClass="active" to="/our_service"
                                      onClick={() => setMenuOpen(false)}>
                                    {t('our_service')}
                                </Link>
                            </Nav.Link>
                            <Nav.Link className="nav-item">
                                <Link className="nav-link text-my-own-color" activeClass="active" to="/why"
                                      onClick={() => setMenuOpen(false)}>
                                    {t('why')}
                                </Link>
                            </Nav.Link>
                            <Nav.Link className="nav-item">
                                <Link className="nav-link text-my-own-color" activeClass="active" to="/how-it-works"
                                      onClick={() => setMenuOpen(false)}>
                                    {t('how_it_works')}
                                </Link>
                            </Nav.Link>
                            <Nav.Link className="nav-item">
                                <Link className="nav-link get_started_now" activeClass="active" to="/get-started-now"
                                      onClick={() => {
                                          setMenuOpen(false);
                                          handleIkigaiClick()
                                      }}>
                                    <div>{t('get_started_now')}</div>
                                </Link>
                            </Nav.Link>
                            <div className=" nav-separator"></div>
                            <Nav.Link className="nav-item">
                                <Link className="nav-link text-my-own-color" activeClass="active" to="/for-developers"
                                      onClick={() => setMenuOpen(false)}>
                                    {t('for_developers')}
                                </Link>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <LanguageFlags/>
                </Container>
            </div>
            <section id="/get-started-now">
                <Modal show={showModal} handleClose={handleCloseModal}>
                    <Calendly/>
                </Modal>
            </section>
            <section id="/"><Main/></section>
            <section id="/our_service"><OurService/></section>
            <section id="/why"><WhyIkigai/></section>
            <section id="/how-it-works"><HowWeWork/></section>
            <section id="/testimonials"><Testimonials/></section>
            <section id="/our-values"><Great/></section>
            <section id="/for-developers"><ForDevelopers/></section>

        </>
    );
}

export default NavMenu;
