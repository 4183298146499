import React, {useEffect, useState} from 'react';
import './OurService.css';
import { useTranslation } from "react-i18next";
import logoBack from "../img/logo.svg";
import logo from "../img/logo2.svg";
import Modal from "./Modal";
import Calendly from "./calendly";

const OurService = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const flipper = document.querySelector('.flipper');
        flipper.classList.add('flipped');
    }, []);

    const handleIkigaiClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="our-service-page">
            <div className="container">
                <div className="our-service">
                    <div className="title service-gradient">{t('our_services_title_1')}
                        <br />
                        <span> + </span>
                        <br />
                        {t('our_services_title_2')}
                    </div>
                </div>
                <div className="our-service-section">
                    <div className="our-service-text-before-list">{t('our_services_text_1')}</div>

                    <ul className="our-service-list">
                        <li className="our-service-text">
                            {t('our_services_text_2')}
                        </li>
                        <li className="our-service-text">{t('our_services_text_3')}</li>
                        <li className="our-service-text">{t('our_services_text_4')}</li>
                    </ul>
                    <div className="our-service-text-before-list">{t('our_services_text_5')}</div>
                    <button type="submit" onClick={handleIkigaiClick} className="btn mt-3 mb-3 border-0 py-2 px-3">
                        <div className="request_free_service">
                            {/*<div className='logo'>*/}
                            {/*      <div className="flip-container">*/}
                            {/*        <div className="flipper">*/}
                            {/*            <div className="front">*/}
                            {/*                <img src={logo} className="d-inline-block align-top logo"*/}
                            {/*                     alt="logo"/>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    /!*</ScrollAnimation>*!/*/}
                            {/*</div>*/}
                            <div>{t('get_a_freelancer')}<i className="ti-arrow-right"></i></div>
                        </div>
                    </button>
                </div>
            </div>
            <Modal show={showModal} handleClose={handleCloseModal}>
                <Calendly />
            </Modal>
        </div>
    );
};

export default OurService;
