import jomigo from "../img/jomigo_logo.png"
import salonkee from "../img/SALONKEE.png"
import mission_om from "../img/MISSION-OM.png"
import plus_ai from "../img/Plus-AI.png"
import dis from"../img/dis_logo.png"


export const reviews = [
    {
        id: 1,
        image: salonkee,
        text: 'I highly recommend Agile Talents Solutions as a trusted partner in software development.\n' +
            '\n' +
            'The developers have outstanding communication skills, allowing them to build strong relationships with every team member and work seamlessly within our team. They communicate clearly, proactively address any issues, and stay aligned with our project goals, which has greatly improved our workflow.',
        name: 'Tom Michels',
        position: 'Co-Founder&CEO, Salonkee',
        rating: 5,
    },
    {
        id: 2,
        image: mission_om,
        text: 'I had the pleasure of working with a developer at Agile Talents, and I can\'t recommend them highly enough! Their technical skills are outstanding, and they consistently delivered high-quality work on time. What impressed me most was their ability to tackle complex problems with innovative solutions and a positive attitude.\n' +
            '\n' +
            'Communication was another strong suit—this developer kept everyone in the loop and was always open to feedback. They collaborated seamlessly with the team, fostering a great working environment. I truly appreciated their dedication and professionalism throughout the project.\n',
        name: 'Alexander Kern',
        position: 'CEO, Mission OM',
        rating: 5,
    },
    {
        id: 3,
        image: jomigo,
        text: 'I can highly recommend working with Eugene and the team if you are looking for very skilled developers in your team! I had the chance to work personally with Eugene on many technical projects in the last few years and I was always impressed by his level of professionalism and the quality of the candidates provided.',
        name: 'Jonathan Muhr',
        position: 'Co-Founder, Jomigo',
        rating: 5,
    },
    {
        id: 4,
        image: dis,
        text: 'Competent in many ways. \n' +
            'We are quite happy with Agile Talents, they find us the perfect developer for our Shopware Development needs. Both developer and business manager have great communication skills, very competent and we love working with them.\n',
        name: 'Karim Bouadim',
        position: 'Head of Development, DIS Software Engineering',
        rating: 5,
    },
    {
        id: 5,
        image: plus_ai,
        text: 'Very Efficient Sourcing, Professional Interview Process. Agilets helped me to grow my business.',
        name: 'Christian Kramer',
        position: 'CTO, Plus AI',
        rating: 5,
    },
];
